@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./datetime.css";
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --orange: #e77051;
  --dark-blue: #264753;
  --sky-blue: #edf6f9;
  --success-green: #2a9d8f;
  --red: #e05252;
  --text-light: #bbb;

  --font-size: 14px;
  --font-size-sm: 11px;
  --font-weight: 600;
}

body {
  margin: 0;
  font-family: poppins, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #222;
  font-size: var(--font-size);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.mb-20 {
  margin-bottom: 5rem;
}

.z-1 {
  z-index: 1;
}

.small-title {
  font-weight: var(--font-weight);
}
.small-text {
  font-size: var(--font-size-sm);
}

.b {
  font-weight: var(--font-weight);
}
.light-b {
  font-weight: 500;
}

.i {
  font-style: italic;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-lh-22 {
  line-height: 5.5rem;
}

.button {
  padding: 10px;
  border-radius: 10px;
  background: #eee;
  transition: filter 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.button:disabled {
  filter: grayscale(1);
}
.button.selectable.active {
  background: var(--orange);
  color: #fff;
}
.button.cta {
  border: 2px solid var(--dark-blue);
  color: var(--dark-blue);
  background: var(--sky-blue);
  font-weight: var(--font-weight);
  font-size: 16px;
}
.button.danger {
  color: #fff;
  background: var(--red);
}

.input {
  border: 1px solid #e7e7e7;
  background: #fff;
  border-radius: 5px;
  padding: 0.6rem 0.8rem;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  font-size: var(--font-size);
  color: #444;
}

.input::placeholder {
  color: var(--text-light);
}

.card {
  border-radius: 1rem;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.error {
  color: var(--red);
}

.notice {
  @apply rounded-xl bg-yellow-100 px-4 py-2 w-full text-yellow-500;
}

.small-icon {
  height: 1.5rem;
}

.flex-center {
  @apply flex items-center justify-center;
}

/* Breadcrumbs */
.breadcrumbs-step .circle,
.breadcrumbs-substep .circle {
  padding: 0.6rem;
  border-radius: 100%;
  height: 0;
  width: 0;
  background: #bbb;
  border: 0.4rem solid #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.breadcrumbs-substep .circle {
  border: 0.4rem solid transparent;
  transform: scale(0.65);
  background: #bbb;
}

.breadcrumbs-step.covered .circle,
.breadcrumbs-substep.covered .circle {
  background: var(--success-green);
}
/* /Breadcrumbs */

.repeatable-item {
  padding: 0.5rem;
  margin: 0.5rem 0;
  border-radius: 1rem;
  background: var(--sky-blue);
}

.fb-button,
.google-button {
  border-radius: 0.25rem;
  padding: 1rem 1rem;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
  font-weight: 500;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fb-button {
  background: #4267b2;
  color: #fff;
}

.google-button {
  background: #fff;
  color: #555;
}

.fb-button .button-icon,
.google-button .button-icon {
  position: absolute;
  left: 0.5rem;
  height: 2rem;
}

.capture-video {
  width: 360px;
  height: 440px;
}

.courses-list {
  @apply mt-3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-3;
}

.course-banner {
  background-image: "url('./course_banner.png')";
  height: 200px;
  max-width: 105%;
  background-image: "url('./course_banner.png')";
  height: 260px;
}
.banner-lt-hd {
  text-align: left;
  font: normal normal bold 45px/53px Raleway;
  letter-spacing: 0px;
  color: #e76f51;
  opacity: 1;
}
.cource_bg {
  background: #f8fcfd 0% 0% no-repeat padding-box;
  opacity: 1;
  padding-top: 20px;
  background: #f8fcfd 0% 0% no-repeat padding-box;
  opacity: 1;
}
.course-leftsidebar {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 12px 3px 74px #0000000a;
  border-radius: 24px;
  opacity: 1;
}
.course-banner-rt img {
  border-top-left-radius: 224px;
}
.banner-text-english {
  text-align: left;
  font: normal normal 600 20px/30px Poppins;
  letter-spacing: 0px;
  color: #264653;
  opacity: 1;
}
.banner-text-course-name {
  text-align: left;
  font: normal normal medium 20px/30px Poppins;
  letter-spacing: 0px;
  color: #2a9d8f;
  opacity: 1;
}
.enrolled-link {
  text-align: center;
  font: normal normal medium 14px/21px Poppins;
  letter-spacing: 0px;
  color: #4d5059;
  opacity: 1;
}
/* .skill2-banner {
	background: transparent url('img/Group 12059.png') 0% 0% no-repeat padding-box;
	opacity: 1;
} */
.search-course {
  background: #edf6f9 0% 0% no-repeat padding-box;
  border: 2px solid #264653;
  border-radius: 7px;
  opacity: 1;
}
.search-field {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 43px #2646531f;
  border: 1px solid #56546d36;
  border-radius: 14px;
  opacity: 1;
  padding-left: 41px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 43px #2646531f;
  border: 1px solid #56546d36;
  border-radius: 14px;
  opacity: 1;
}
.search-icon {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #e76f51;
  opacity: 1;
}
.completed-course {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 99px #0000000a;
  border-radius: 13px;
  opacity: 1;
}
.completed-course-details {
  text-align: left;
  letter-spacing: NaNpx;
  color: #4d5059;
  opacity: 1;
}
.ongoing-course {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 99px #0000000a;
  border-radius: 13px;
  opacity: 1;
}
.ongoing-course-details {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 30px #2646531a;
  opacity: 1;
}
.calender-btn {
  background: #edf6f9 0% 0% no-repeat padding-box;
  border: 1px solid #2646534f;
  border-radius: 7px;
  opacity: 1;
}
.see-all {
  text-align: center;
  font: normal normal normal 17px/30px Poppins;
  letter-spacing: 0px;
  color: #264653;
  opacity: 1;
}
.dock5 {
  background: transparent 0% 0% no-repeat padding-box;
  opacity: 1;
}
.download-button {
  background: #e76f51 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
}

@media only screen and (max-width: 768px) {
  .custom-lh-22 {
    line-height: 1.7rem;
  }
}
.live-class {
  background: #e76f51 0% 0% no-repeat padding-box;
  border-radius: 7px;
  opacity: 1;
}
.live-class-badge {
  text-align: center;
  font: normal normal normal 14px/21px Poppins;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.search-icon {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #e76f51;
  opacity: 1;
}
.progress-bar {
  /* background: #fafafa 0% 0% no-repeat padding-box; */
  background-color: #fafafa;
  box-shadow: inset 0px 0px 14px #0000001a;
  border-radius: 8px;
  opacity: 1;
}
.progress-bar2 {
  background: transparent linear-gradient(89deg, #f4a261 0%, #df6b4e 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
}

.slick-custom {
  display: flex !important;
  margin: auto;
}
button.slick-arrow.slick-next {
  position: absolute;
  right: 10px;
  top: 0;
  /* color: red; */
  /* background: #edf6f9 0% 0% no-repeat padding-box; */
  /* border: 1px solid #afbfc5; */
  background-color: lightskyblue;
  opacity: 1;
}
button.slick-arrow.slick-prev {
  position: absolute;
  right: 35px !important;
  background-color: lightskyblue;
  left: 94%;
  top: 0;
  /* background: #edf6f9 0% 0% no-repeat padding-box; */

  /* color: red; */
  /* background: #edf6f9 0% 0% no-repeat padding-box; */
  /* border: 1px solid #afbfc5; */
  opacity: 1;
}
.ongoing-image {
  height: 276px;
}
.rounded-md .pt-1 {
  margin-top: 100px;
}
.download-btn {
  margin-top: 2.5rem;
}
.slider-height {
  height: 24rem;
}

@media screen and (max-width: 640px) {
  .slick-custom {
    display: block !important;
  }
  .course-leftsidebar li {
    display: inline-block;
  }
}

@media only screen and (min-width: 640px) {
  /* sm */
  .custom-lh-22 {
    line-height: 1.7rem;
  }
}

@media only screen and (min-width: 769px) {
  /* md */
  .capture-video {
    width: 720px;
  }
}

@media only screen and (min-width: 1024px) {
  /* lg */
  .capture-video {
    width: 900px;
  }
}

@media only screen and (min-width: 1280px) {
  /* xl */
}

@media only screen and (min-width: 1536px) {
  /* 2xl */
}

.list {
  border: 1px solid orange;

  list-style-type: none;
}

/*
.details{
  color: #264BC4;
  font-size: 12px;
  }


  #quiz .options input[type=checkbox] {
    height: 22px;
    margin-right: 6px;
    width: 22px;
}

.cursor-pointer {
    cursor: pointer;
}

.result-question {
    background-color: #eee;
    margin: 4px;
    padding: 6px;
}

/* Create Quiz Styles 
.create-quiz .question {
    text-align: left;
}

.create-quiz input[type=text] {
    border: 1px solid #ddd;
    width: 95%;
}

.create-quiz .tb-quiz-name {
    background-color: #ccffcc;
    text-align: center;
}

.create-quiz .tb-desc {
    background-color: #fff;
}

.create-quiz .options > div {
    margin-left: 20px;
}

.create-quiz .options input[type=checkbox] {
    border: 1px solid #262626;
    display: inline-block;
    height: 20px;
    margin-top: 10px;
    margin-bottom: 0;
    padding: 0;
    width: 20px;
}
*/

.Collapsible {
  background-color: transparent;
}

.Collapsible__contentInner {
  border-top: 0;
}

p {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
}
p:last-child {
  margin-bottom: 0;
}

.Collapsible__trigger {
  display: block;
  font-weight: 600;
  text-decoration: none;
  position: relative;
  border: 1px solid white;
  padding: 10px;
  background: rgb(224, 224, 224);
  color: black;
}
.Collapsible__trigger :after {
  font-family: "FontAwesome";
  content: "\f107";
  position: absolute;
  right: 10px;
  top: 10px;
  display: block;
  transition: transform 300ms;
}

/*.Collapsible__trigger.is-open {
	transform: rotateZ(180deg);
}*/
.Collapsible__trigger:after {
  transform: rotateZ(180deg);
}

.Collapsible__trigger .is-disabled {
  opacity: 0.5;
  background-color: grey;
}

.CustomTriggerCSS {
  background-color: lightcoral;
  transition: background-color 200ms ease;
}

.CustomTriggerCSS--open {
  background-color: darkslateblue;
}

.Collapsible__custom-sibling {
  padding: 5px;
  font-size: 12px;
  background-color: #cbb700;
  color: black;
}

.Collapsible__trigger.is-open .lesson-arow {
  transform: rotateZ(90deg);
}

/* React big calendar */
.rbc-event {
  background: var(--orange) !important;
}

.purchase-demoed-course-card {
  --overlay-transparent-color: rgba(0, 0, 0, 0);
  --overlay-opaque-color: rgba(240, 240, 240, 1);
}

.purchase-demoed-course-card .thumbnail-overlay {
  background: linear-gradient(
    to bottom,
    var(--overlay-transparent-color),
    var(--overlay-opaque-color)
  );
}

.purchase-demoed-course-card .text {
  background: var(--overlay-opaque-color);
}

@media only screen and (min-width: 768px) {
  .purchase-demoed-course-card .thumbnail-overlay {
    background: linear-gradient(
      to right,
      var(--overlay-transparent-color),
      var(--overlay-opaque-color)
    );
  }
}

.cricle-width {
  border-width: 40px;
}
.outer-circle-border {
  border-color: #1b7d39;
}

.inner-circle-border {
  border-color: #35b75d;
}

.input-range__track--active,
.input-range__slider {
    background: var(--orange);
    border-color: var(--orange);
} 

.zindex-custm{z-index: 1 !important;}
